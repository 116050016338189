<template>
    <p-secure require-admin>
        <SearchScreen ref="searchScreen"
                      title="Users"
                      api-destination="users"
                      :filters="filters"
                      :filterBy="filterBy"
                      :fields="fields"
                      add-new-button
                      edit-key="userId"
                      @isActiveChanged="updateIsActiveStatus">
            <template v-slot:afterSearch>
                <b-col cols="3">
                    <p-select class="h-auto ml-2 shadow-none"
                              v-model="filters.returnOnlyActiveRecords"
                              :options="searchOptions" />
                </b-col>
            </template>
            <template v-slot:belowSearch>
                <b-row class="ml-2 mr-1 mt-2">
                    <b-col cols="12" sm="4" xl="2">
                        <b-form-checkbox v-model="tenantTwoFactorEnabled" name="check-button" switch @change="toggleTenantTwoFactorEnabled">
                            Enable 2FA for all users
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </template>
            <template #cell(twoFactorEnabled)="data">
                <b-icon-check v-if="data.item.twoFactorEnabled || tenantTwoFactorEnabled" scale="1.5" class="p-2" variant="success"></b-icon-check>
                <b-icon-x v-else scale="1.5" class="p-2" variant="danger"></b-icon-x>
            </template>
        </SearchScreen>
    </p-secure>
</template>

<script>
import axios from 'axios';
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            tenantTwoFactorEnabled: false,
            filterName: 'UsersFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'displayName',
                sortDesc: true,
                searchBy:'All',
                returnOnlyActiveRecords: true
            },
            filterBy:[
                'All',
                'Display Name',
                'Username',
                'Email'
            ],
            searchOptions: [
                { value: null, text: 'All Users' },
                { value: true, text: 'Active Users' },
                { value: false, text: 'Inactive Users' }
            ],
            fields: [
                {
                    key: 'displayName',
                    sortable: true
                },
                {
                    key: 'userName',
                    sortable: true
                },
                {
                    key: 'email',
                    sortable: true
                },
                {
                    key: 'isActive',
                    sortable: false
                },
                {
                    key: 'twoFactorEnabled',
                    label: '2FA Enabled',
                    sortable: true
                }
            ]
        };
    },
    components: {
        SearchScreen
    },
    mounted: async function () {
        const result = await axios.get('utility/tenantTwoFactorEnabled');
        this.tenantTwoFactorEnabled = result.data;
    },
    methods: {
        async toggleTenantTwoFactorEnabled(checked) {
            const msg = `You are about to ${ checked ?'activate': 'de-activate' } 2FA for ALL users. Are you sure this is what you want to do?`;
            const confirm = await this.$bvModal.msgBoxConfirm(msg);
                
            if (confirm) {
                const result = await axios.post('utility/toggleTenantTwoFactorEnabled', { twoFactorEnabled: checked });

                if (result) {
                    this.$toasted.global.app_success(`2FA for all users ${checked ? 'activated' : 'de-activated'
                        } successfully`).goAway(5000);
                }
            } else {
                //revert user action
                this.tenantTwoFactorEnabled = !checked;
            }
        },
        updateIsActiveStatus(user) {
            var self = this;
            axios.post('users', user).then(() => {
                self.$refs.searchScreen.refreshSearchResults();
                var msg = `${user.userName} ${
                    user.isActive ? 'activated' : 'de-activated'
                } successfully`;
                this.$toasted.global.app_success(msg).goAway(5000);
            });
        }
    },
};
</script>
<style scoped>
>>> .custom-select{
    height: 43px;
}
</style>
