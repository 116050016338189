<template>
    <div>
        <p-form @submit="saveDcc" id="dcc-edit-form">
            <b-card v-for="(dccElement, index) in dccElements"
                    :key="dccElement.dccLevel"
                    class="">
                <b-row cols="1" cols-md="2" cols-xl="3">
                        <b-col>
                            <p-input :label="dccElement.dccLevel + ' Name'"
                                    :disabled="dccElement.disabled"
                                    v-model="dccElement.name"
                                    rules="max:50|required" />
                        </b-col>
                    <b-col>
                        <p-number
                            :label="dccElement.dccLevel + ' Number'"
                            v-model.number="dccElement.number"
                            :disabled="dccElement.disabled || !dccElement.isNew"
                            input-type="integer"
                            rules="min_value:0|max_value:99|required|integer"
                        />
                    </b-col>
                    <b-col v-if="dccElement.dccLevel === 'Department'">
                        <p-select
                                    dataType="productTypes"
                                    no-selection-message="Select a type"
                                    label="DCC Type"
                                    v-model.number="dccElement.type"
                                    :disabled="dccElement.disabled"
                                    rules="required|integer" />
                    </b-col>
                    <b-col>
                        <p-number
                            v-model="dccElement.desiredMarginPercent"
                            :disabled="dccElement.disabled"
                            label="Desired Margin (%)"
                            v-if="!dccElement.isNew || dccElement.dccLevel === 'Category'"
                            rules="p-decimal:10,2|min_value:0"
                            input-type="percent"
                            @change="combinedCategorySettingChange(dccElement, 'desiredMarginPercent')"
                            :infoMessage="updateTooltip(dccElement)"
                        />
                    </b-col>
                    <b-col>
                        <p-select dataType="priceTagTypes"
                            :disabled="dccElement.disabled"
                                    label="Tag Type"
                                    v-if="!dccElement.isNew || dccElement.dccLevel === 'Category'"
                                    @change="combinedCategorySettingChange(dccElement, 'priceTagTypeId')"
                                    :infoMessage="updateTooltip(dccElement)"
                                    v-model="dccElement.priceTagTypeId" />
                    </b-col>
                    <b-col>
                        <p-select dataType="taxItems"
                            :disabled="dccElement.disabled"
                                    label="Tax Item"
                                    v-if="!dccElement.isNew || dccElement.dccLevel === 'Category'"
                                    :rules='dccElement.dccLevel === "Category" ? "required" : ""'
                                    @change="combinedCategorySettingChange(dccElement, 'taxItemId')"
                                    :infoMessage="updateTooltip(dccElement)"
                                    v-model="dccElement.taxItemId" />
                    </b-col>
                </b-row>                
                <b-row cols="1" cols-md="2" cols-xl="3">
                    <b-col v-if="!dccElement.isNew">
                        <p-checkbox label="Is Active?"
                                    class=""
                                    use-indeterminate
                                    :disabled="dccElement.disabled"
                                    v-model="dccElement.isActive"
                                    @change="combinedCategorySettingChange(dccElement, 'isActive')"
                                    :infoMessage="updateTooltip(dccElement)" />
                    </b-col>
                    <b-col>
                        <p-checkbox class=""
                                    label="Allow Discounts"
                                    v-if="!dccElement.isNew || dccElement.dccLevel === 'Category'"
                                    use-indeterminate
                                    :disabled="dccElement.disabled"
                                    v-model.number="dccElement.allowsDiscounts"
                                    @change="combinedCategorySettingChange(dccElement, 'allowsDiscounts')"
                                    :infoMessage="updateTooltip(dccElement)" />
                    </b-col>
                        <b-col v-if="dccElement.disabled">
                            <span class="float-right pl-2">
                                <b-button class="mb-3 text-right"
                                        @click="dccElement.disabled = false;">Edit</b-button>
                            </span>
                        </b-col>

                </b-row>
                <!-- hr to help keep things clear on smaller screens -->
                <hr v-if="index < dccElements.length - 1"  class="d-xl-none" />
            </b-card>
        </p-form>
        <b-row>
            <b-col class="text-center">
                <b-button variant="primary" type="submit" form="dcc-edit-form">Save</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from "axios";
import NavigationGuard from '@/components/mixins/NavigationGuard.js';

export default {
    name: "edit-dcc",
    mixins: [NavigationGuard],
    props: {
        selectedDcc: [Object],
        dccs: [Array]
    },
    data() {
        return {
            dccElements: []
        };
    },
    computed: {
        disableDepartment() {
            return (
                !this.selectedDcc.department.isNew &&
                !!this.selectedDcc.class
            );
        },
        disableClass() {
            return (
                !this.selectedDcc.class.isNew &&
                !!this.selectedDcc.category
            );
        },
        updatedDepartment() {
            return this.dccElements[0];
        },
        updatedCategory() {
            return this.dccElements.find(x => x.dccLevel === "Category");
        },
        updatedClass() {
            return this.dccElements.find(x => x.dccLevel === "Class");
        },
        allClassCategories(){
            return this.selectedDcc?.class?.dccCategories ?? [];
        },
        allDepartmentCategories(){
            return this.selectedDcc?.department?.dccClasses?.flatMap(x => x.dccCategories) ?? [];
        }
    },
    methods: {
        updateTooltip(dccElement){
            if(dccElement.disabled) {return null;}
            if(dccElement.dccLevel === "Category") {return null;}

            let categoryCount = dccElement.dccLevel === "Department" ? this.allDepartmentCategories.length : this.allClassCategories.length;
            let countMessage = categoryCount > 1 ? `all ${categoryCount} categories` : `the ${categoryCount} category `

            if(!this.updatedCategory || categoryCount < 2){
                return `Updates ${countMessage} belonging to this ${dccElement.dccLevel.toLowerCase()}.`;
            }
            return `Updates ${countMessage} belonging to this ${dccElement.dccLevel.toLowerCase()}, not just the category shown below.`
        },

        saveDcc() {
            let data = {
                dccDepartment: this.updatedDepartment
            };
            if(data.dccDepartment.disabled )
            {
                //we don't need to spend time updating every category in this department if
                //department level properties weren't updated.
                this.removeCombinedCategorySettings(data.dccDepartment);
            }

            if (this.updatedClass) {
                data.dccDepartment.dccClass = this.updatedClass;
                if(data.dccDepartment.dccClass.disabled){
                    this.removeCombinedCategorySettings(data.dccDepartment.dccClass);
                }
            }
            if (this.updatedClass) {
                data.dccDepartment.dccClass.dccCategory = this.updatedCategory;
            }
            axios.post("dccs", data).then(() => {
                if (this.dccElements.some(x => x.isNew)) {
                    //if we returned newly created objecst (primarily ids)
                    //from the response we could update live
                    this.$toasted.global.app_success("DCC saved successfully.").goAway(5000);
                    this.$emit("reload-dccs");
                    return;
                }
                //update the dccs, to avoid a page reload
                if (this.updatedDepartment) {
                    this.updateDccElement(
                        this.updatedDepartment,
                        this.selectedDcc.department,
                        this.allDepartmentCategories
                    );
                }
                if (this.updatedClass) {
                    this.updateDccElement(
                        this.updatedClass,
                        this.selectedDcc.class,
                        this.allClassCategories
                    );
                }
                if (this.updatedCategory) {
                    this.updateDccElement(
                        this.updatedCategory,
                        this.selectedDcc.category
                    );
                }
                this.dataSaved();
                this.$toasted.global.app_success("DCC saved successfully.").goAway(5000);
            });
        },

        updateDccElement(source, destination, childCategories) {
            destination.name = source.name;
            destination.number = source.number;
            destination.type = source.type;

            //update these properties for any child categories when they have
            //been set at either the department or class level.
            [destination, ...(childCategories ?? [])]?.forEach(destination => {
                if(source?.desiredMarginPercent != null){
                    destination.desiredMarginPercent = source.desiredMarginPercent;
                }
                if(source?.allowsDiscounts != null){
                    destination.allowsDiscounts = source.allowsDiscounts;
                }
                if(source?.isActive != null){
                    destination.isActive = source.isActive;
                }
                if(source?.priceTagTypeId != null){
                    destination.priceTagTypeId = source.priceTagTypeId;
                }
                if(source?.taxItemId != null){
                    destination.taxItemId = source.taxItemId;
                }
            });
        },

        getCombinedCategorySetting(categories, propertyName){
            return  categories?.every(x => x === undefined ||  x[propertyName] === categories[0]?.[propertyName])
                        ? categories[0]?.[propertyName] : null;
        },
        getCombinedCategorySettings(categories) {
            //some category level properties are allowed to be set the department/class level.
            //we get the single value if they are all the same, otherwise we set it to null.
            return {
                desiredMarginPercent : this.getCombinedCategorySetting(categories, 'desiredMarginPercent'),
                allowsDiscounts : this.getCombinedCategorySetting(categories, 'allowsDiscounts'),
                isActive : this.getCombinedCategorySetting(categories, 'isActive'),
                priceTagTypeId : this.getCombinedCategorySetting(categories, 'priceTagTypeId'),
                taxItemId : this.getCombinedCategorySetting(categories, 'taxItemId'),
            }
        },
        removeCombinedCategorySettings(dccElement){
            delete dccElement.desiredMarginPercent;
            delete dccElement.allowsDiscounts;
            delete dccElement.isActive;
            delete dccElement.priceTagTypeId;
            delete dccElement.taxItemId;
        },
        combinedCategorySettingChange(parentElement, updatedPropertyName){
            let newValue = parentElement[updatedPropertyName];

            //don't have a cateogory if we selected a dept or class.
            if(this.updatedCategory){
                this.updatedCategory[updatedPropertyName] = newValue;
            }

            if(parentElement.dccLevel === "Department" && this.updatedClass){
                this.updatedClass[updatedPropertyName] = newValue;
            }
            else if(parentElement.dccLevel === "Class"){
                this.updatedDepartment[updatedPropertyName] = this.getCombinedCategorySetting([this.updatedCategory, ...this.allDepartmentCategories], updatedPropertyName);
            }
            else if(parentElement.dccLevel === "Category"){
                this.updatedDepartment[updatedPropertyName] = this.getCombinedCategorySetting([this.updatedCategory, ...this.allDepartmentCategories], updatedPropertyName);
                this.updatedClass[updatedPropertyName] = this.getCombinedCategorySetting([this.updatedCategory, ...this.allClassCategories], updatedPropertyName);
            }
        },

        loadDccElements() {
            if (!this.selectedDcc.department) {
                return [];
            }
            //find what elements should be rendered, and configure their display mode
            //I wanted this to be computed, but then it isn't reactive when binding
            //to v-model (causing validation issues)
            var elements = [
                {
                    dccLevel: "Department",
                    disabled: this.disableDepartment,
                    ...this.selectedDcc.department,
                    ...this.getCombinedCategorySettings(this.allDepartmentCategories)
                }
            ];
            let classSettings = (this.selectedDcc.class?.isNew ?
                        this.getCombinedCategorySettings(this.allDepartmentCategories)
                        : this.getCombinedCategorySettings(this.allClassCategories))
            if (this.selectedDcc.department.isNew || this.selectedDcc.class) {
                elements.push({
                    dccLevel: "Class",
                    disabled: this.disableClass,
                    ...this.selectedDcc.class,
                    ...classSettings
                });
            }

            if (elements.some(x => x.isNew) || this.selectedDcc.category) {
                let parentDefaults = this.selectedDcc.category.isNew ? classSettings : {}
                elements.push({
                    dccLevel: "Category",
                    disabled: false,
                    ...this.selectedDcc.category,
                    ...parentDefaults
                });
            }
            this.dccElements = elements;
            this.dataLoaded();
        }
    },
    watch: {
        dccElements: NavigationGuard.$watcher,
        selectedDcc: {
            handler: async function() {
                this.loadDccElements();
            },
            deep: true
        }
    },
    mounted: function() {
        this.loadDccElements();
    }
};
</script>
